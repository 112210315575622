import * as React from 'react';
import { AppProps, SeoObject } from 'src/app/types';
import { Localized, Typography } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';

import { AppPageLayout, Wishlist } from 'src/app.consumer/components';

const seo: SeoObject = {
  title: 'Your wishlist',
};

const WishlistsPage = (props: AppProps) => {
  const { isLoggedIn, user } = useAppContext();

  return (
    <AppPageLayout
      subTitle={<Localized dictKey={'staticLocales:pages./wishlists.subtitle'} />}
      title={<Localized dictKey={'staticLocales:pages./wishlists.link label'} />}
      {...{ ...props, seo }}
    >
      {!isLoggedIn ? (
        <Typography>
          <Localized dictKey={'staticLocales:pages./wishlists.warning.account needed'} />
        </Typography>
      ) : null}

      {isLoggedIn && user?.uid ? <Wishlist /> : null}
    </AppPageLayout>
  );
};

export default WishlistsPage;
